<template>
  <div class="main-container">
    <img src="@/assets/image/caredokter.svg" class="img-care-dokter" />
    <!-- <div class="flex-box-center"> -->
    <div class="flex-box-center">
      <div class="my-auto text-center">
        <div
          class="margin-bottom-24"
          ref="dot"
          v-if="!browserNotSupported && !permisisonSteps && !failedInfo"
        >
          <div
            class="spinner-grow spinner-grow-sm c-pink-care-doctor "
            :class="isVisibility[0] ? 'isVisibilty' : 'isVisibilty-hidden'"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <div
            class="spinner-grow spinner-grow-sm c-pink-care-doctor "
            :class="isVisibility[1] ? 'isVisibilty' : 'isVisibilty-hidden'"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <div
            class="spinner-grow spinner-grow-sm c-pink-care-doctor "
            :class="isVisibility[2] ? 'isVisibilty' : 'isVisibilty-hidden'"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <p class="c-gray-A fs-32 fw-600 m-0">
            Menunggu izin dari browser Anda
          </p>
        </div>

        <div v-if="browserNotSupported">
          <h4>
            your browser not supported, for better experience please use google
            chrome or safari
          </h4>
          <a href="https://www.google.com/chrome/">click here</a>
        </div>

        <div v-if="beg">
          <p class="fs-20 fw-500 c-primary m-0">
            Mohon berikan izin kepada kamera dan mikrofon pada browser Anda
          </p>
          <p class="fs-20 fw-500 c-primary m-0">
            Tanpa izin, kami tidak dapat menyambungkan Anda untuk Telekonsultasi
          </p>
        </div>

        <div class="my-auto" v-if="permisisonSteps">
          <block-permission @sendWhatsapp="sendWA" @goTerm="goToTerm" />
        </div>
        <div class="my-auto" v-else-if="failedInfo">
          <failed-link-data @sendWhatsapp="sendWAError" @goTerm="goToTerm" />
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import BlockPermission from "@/components/checkPermission/BlokPermission.vue";
import Repository from "@/helpers/repository";
import sendWhatsapp from "@/helpers/sendText";
import FailedLinkData from "../components/checkPermission/FailedLinkData.vue";
export default {
  components: {
    BlockPermission,
    FailedLinkData,
  },
  data() {
    return {
      browserNotSupported: false,
      beg: false,
      permisisonSteps: false,
      workingWith: true,
      isVisibility: [true, false, false],
      dataUser: {},
      failedInfo: false,
    };
  },
  methods: {
    async sendWA() {
      const params = {
        ...this.$route.query,
      };
      const getConfig = await Repository.getConfigMessage(params.tenantId);
      const keyDokter = this.$route.query.key ? true : false;
      let message = "";
      if (keyDokter) {
        message = getConfig.message.dokter;
      } else {
        message = getConfig.message.pasien;
      }
      const urlWA = sendWhatsapp.sendWhatsapp(this.dataUser, message);
      setTimeout(() => {
        window.open(`${urlWA}`, "_blank");
      }, 100);
    },
     async sendWAError() {
      const params = {
        ...this.$route.query,
      };
      const getConfig = await Repository.getConfigMessage(params.tenantId);
      let message = getConfig.message.error;
      const urlWA = sendWhatsapp.sendWhatsapp(this.dataUser, message);
      setTimeout(() => {
        window.open(`${urlWA}`, "_blank");
      }, 100);
    },
    goToTerm() {
      this.$router.push({ name: "terms-and-conditions" });
    },
    pindah() {
      if(this.failedInfo) return false
      let path = this.$route.fullPath;
      sessionStorage.setItem("permission", "allowed");
      this.$router.push(`waitingRoom?${path.substring(17)}`);
    },
    promptToAsk() {
      let vx = this;

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function(stream) {
          // alert("got permission", stream);
          // vx.setSession();
          vx.pindah();
        })
        .catch(function(err) {
          vx.permisisonSteps = true;
          vx.beg = false;
          // alert("failed to ask", err);
        });
    },
    setSession() {
      sessionStorage.setItem("permission", true);
    },
    checkBrowser() {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) != -1
      ) {
        return "Opera";
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return "Firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true
      ) {
        //IF IE > 10
        return "IE";
      } else {
        return "unknown";
      }
    },
  },
  async mounted() {
    if (this.$refs["dot"]) {
      const isFuncVisible = (num) => {
        let numSelect = num;
        if (numSelect > 2) numSelect = 0;
        let arrVisible = [false, false, false];
        arrVisible[numSelect] = true;
        return arrVisible;
      };
      let num = 0;
      this.isVisibility = isFuncVisible(num);
      setInterval(() => {
        if (num > 2) num = 0;
        this.isVisibility = isFuncVisible(num);
        num++;
      }, 1000);
    }

    let vx = this;
     let params = {
      ...this.$route.query,
    };
    if (!params.linkId) {
        params = sessionStorage.queryParam ? JSON.parse(sessionStorage.queryParam) : {}
    }
    const [dataUser, whatsapp] = this.$store.state.allDataTelekonsul
     if (dataUser.status == "fulfilled" && whatsapp.status == "fulfilled") {
        if (!params.tenantId) {
          whatsapp.value = await Repository.getNumberContact(dataUser.value.reservasi.tenantId)
        }
        this.dataUser = {
          ...dataUser.value,
          ...whatsapp.value,
        };
      } else if (
        dataUser.status == "rejected" &&
        whatsapp.status == "fulfilled" &&
        params.tenantId
      ) {
        this.dataUser = {
          ...whatsapp.value,
          link: decodeURIComponent(window.location.href)
        };
        this.failedInfo = true;
      } else {
        this.failedInfo = true;
        this.$router.push({
          name: "notFound",
        });
      }

    var isWebview =
      (navigator.userAgent.indexOf("Android") !== -1 &&
        navigator.userAgent.indexOf("wv") !== -1) ||
      ((navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1) &&
        navigator.userAgent.indexOf("Safari") == -1);

    if (this.$route.query.bypass == "mobile" || isWebview) {
      vx.pindah();
    } else {
      try {
        navigator.permissions
          .query({ name: "microphone", name: "camera" })
          .then(function(result) {
            if (result.state == "granted") {
              // alert(result.state);
              vx.pindah();
              // vx.setSession();
            } else if (result.state == "prompt") {
              vx.beg = true;
              vx.promptToAsk();
              // alert(result.state);
            } else {
              // alert(result.state);
              vx.promptToAsk();
            }
          });
      } catch (e) {
        // console.log(vx.checkBrowser());
        if (vx.checkBrowser() == "Safari") {
          this.promptToAsk();
        } else {
          alert("Browser Not Supported");
          this.browserNotSupported = true;
        }
        // sarankan dengan browser lain
      }
    }
  },
};
</script>

<style></style>
