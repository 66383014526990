<template>
  <div class="main-telekonsul">
    <div class="nav1 mb-4">
      <div class="row" style="top: 50%">
        <div class="col-sm text-left" style="">
          <img
            alt="Care Dokter Logo"
            style="height: 45px;"
            src="../assets/care_dokter_logo.png"
          />
        </div>
        <!-- <div class="col-sm-12"> -->
            <span class="fs-24 fw-700 c-primary">Telekonsultasi</span>
          <!-- <h5><strong>Tele</strong>Health</h5> -->
        <!-- </div> -->
        <div class="col-sm text-right" v-if="jadwal.dokter">
          <p class="c-primary fs-20 fw-700 lh-24 margin-bottom-8"> 
            <strong>{{ jadwal.dokter.nama }}</strong>
          </p>
          <p class="fs-16 c-primary fw-700 lh-20 margin-bottom-4">
            {{ jadwal.dokter.tujuanRujukan.namaIndo }}
          </p>
          <p class="fs-16 c-gray-A fw-700 lh-20 margin-bottom-4">
            {{ hari }}, {{ tanggalKunjungan }}
          </p>
          <p class="fs-16 c-gray-A fw-700 lh-20 m-0">
            {{ jadwal.jamMulai }} - {{jadwal.jamSelesai}}
          </p>
        </div>
      </div>
    </div>
    <div class="nav2">
      <!-- <div>
        <img
            alt="Care Dokter Logo"
            style="height: 30px;"
            src="../assets/care_dokter_logo.png"
          />
      </div>
      <div class="ml-auto text-right">
        <p class="c-primary fs-20 fw-700 lh-24 margin-bottom-8"> 
            <strong>{{ jadwal.dokter.nama }}</strong>
          </p>
          <p class="fs-16 c-primary fw-700 lh-20 margin-bottom-4">
            {{ jadwal.dokter.tujuanRujukan.namaIndo }}
          </p>
          <p class="fs-16 c-gray-A fw-700 lh-20 margin-bottom-4">
            {{ jadwal.hari }}, {{ reservasi.tanggalKunjungan }}
          </p>
          <p class="fs-16 c-gray-A fw-700 lh-20">
            {{ jadwal.jamMulai }} - {{jadwal.jamSelesai}}
          </p>
      </div> -->
      <div class="row">
        <div class="col-4 text-left" style="">
          <img
            alt="Care Dokter Logo"
            style="height: 30px;"
            src="../assets/care_dokter_logo.png"
          />
        </div>
        <div class="col-8 text-right" v-if="jadwal.dokter">
         <p class="c-primary fs-12 fw-700 lh-15 margin-bottom-2"> 
           {{ jadwal.dokter.nama }}
          </p>
          <p class="fs-10 c-primary fw-600 lh-12 margin-bottom-2">
            {{ jadwal.dokter.tujuanRujukan.namaIndo }}
          </p>
          <p class="fs-8 c-gray-A fw-600 lh-10 margin-bottom-4">
            {{ hari }} {{ tanggalKunjungan }} , {{ jadwal.jamMulai }} - {{jadwal.jamSelesai}}
          </p>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <div class="row" ref="camera-data" >
        <div class="col-sm-12 col-lg-9 vidcontain" >
          <div id="layoutContainer">
            <div id="publisherContainer" class="publisher"></div>
          </div>
          <button
            class="my-float float-com animate__animated animate__fadeInUp "
            @click.prevent="togglePartiMobile('open')"
            style="top:22.5%;"
          >
            <i class="fas fa-user-friends"></i>
          </button>
          <div
            id="partiMobile"
            class="animate__animated animate__fadeInUp "
            v-show="partiMobile"
          >
            <button
              class="float-right float-com "
              style="top:5%; color:red;"
              @click="togglePartiMobile('close')"
            >
              <i class="fa fa-times"></i>
            </button>
            <div class=" mb-2" style="margin-top:5vh; ">
              <h6
                style="color: #3A77B7 left:40vw; ; font-size:30px; color:white;"
              >
                <strong>Participant ({{ streams.length }})</strong>
              </h6>
              <modalShareLink infoId="'mobile'" />
              <div class="container mt-4">
                <div
                  v-for="(user, index) in streams.slice().reverse()"
                  :key="index"
                  class=""
                  style="height: 40px;"
                >
                  <div class="row mt-3">
                    <div
                      style="padding-right: 0; padding-left: 9vw; display: flex;"
                      class="col-10 row text-truncate"
                    >
                      <div
                        class="btn-circle avatar mr-2"
                        style="width: 30px;	
                          height: 30px;	
                          line-height: 30px;"
                      >
                        {{ user.name.split("-")[1][0].toUpperCase() }}
                      </div>
                      <p
                        style="margin:0; padding-top:; font-size: 20px; text-align: center;	
    align-self: center; color: white;"
                        v-if="user.name.split('-')[1] !== userName"
                      >
                        {{ user.name.split("-")[1] }}
                      </p>
                      <p
                        style="margin:0; padding-top:; font-size: 20px; text-align: center;	
    align-self: center; color: white;"
                        v-else
                      >
                        {{ user.name.split("-")[1] }} (Me)
                      </p>
                    </div>
                    <div class="col-2 ml-3 pt-1 mt-1" style="padding: 0;">
                      <i
                        class="fas fa-video buttonGreen fa-lg mr-2"
                        v-if="user.hasVideo"
                        style=""
                      ></i>
                      <i
                        v-else
                        class="fas fa-video-slash fa-lg mr-2"
                        style=""
                      ></i>
                      <i
                        class="fas fa-microphone fa-lg buttonGreen"
                        v-if="user.hasAudio"
                        style=""
                      ></i>
                      <i
                        v-else
                        class="fas fa-microphone-slash fa-lg"
                        style=""
                      ></i>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <button
            class="my-float float-com animate__animated animate__fadeInUp mt-3"
            @click.prevent="closeChatMobile('open')"
          >
            <span class="badge badge-danger" v-if="newNotif > 0">{{
              newNotif
            }}</span>
            <i class="fa fa-comments"></i>
          </button>
          <button
            class="my-float float-com animate__animated animate__fadeInUp mt-3 top-wa"
            @click.prevent="sendWA"
          >
            <i class="fab fa-whatsapp"></i>
          </button>
          <div
            id="chatMobile"
            class="animate__animated animate__fadeInUp "
            v-show="chatMobile"
          >
            <button
              class="float-right float-com "
              style="top:5%; color:red;"
              @click="closeChatMobile('close')"
            >
              <i class="fa fa-times"></i>
            </button>
            <h6
              class="mb-5"
              style="margin-top:5vh; left:40vw; font-size:30px; color:white;"
            >
              Chat
            </h6>
            <div
              id="chat-box-mobile"
              style="overflow-y: scroll; scroll-behavior: smooth;"
            >
              <div
                class="container"
                v-for="(chat, index) in chats"
                :key="index"
              >
                <!-- {{ chat.content }} -->
                <div
                  class="row ml-1 justify-content-start"
                  v-if="chat.user.id !== publisherData.id"
                >
                  <div class="pt-1">
                    <div
                      class="btn-circle avatar mr-2"
                      style="width:25px; height:25px; line-height:25px; font-size:12px; float:right;"
                    >
                      {{ chat.user.name.split("-")[1][0].toUpperCase() }}
                    </div>
                  </div>
                  <div
                    class=""
                    style="padding-left: 0; background: #dce2e4; border-radius: 5px; padding: 7px; width: 80vw;"
                  >
                    <img
                      v-if="chat.props"
                      :src="chat.content"
                      alt=""
                      style="width: 100%; height: 100%;"
                      @click="mencoba(chat.content)"
                    />
                    <p
                      v-else
                      class="text-left"
                      style="margin: 0;  word-wrap: break-word; color:#265b5d"
                    >
                      {{ chat.content }}
                    </p>
                    <span
                      class="time-right ml-1"
                      style="float:left; font-size: 10px; color:#265b5d"
                      >{{ chat.timestamp }}</span
                    >
                  </div>
                </div>
                <div
                  class="row mr-1 justify-content-end"
                  v-else
                  style="padding: 0px; "
                >
                  <div
                    class=""
                    style="padding-right: 0; background: #96d2e8; border-radius: 5px; padding: 7px; width: 80vw;"
                  >
                    <img
                      v-if="chat.props"
                      :src="chat.content"
                      alt=""
                      style="width: 100%; height: 100%;"
                      @click="mencoba(chat.content)"
                    />
                    <p
                      v-else
                      class="text-right text-wrap"
                      style="margin: 0; word-wrap: break-word; color:#265b5d;"
                    >
                      {{ chat.content }}
                    </p>
                    <span
                      class="time-right mr-1"
                      style="float:right; font-size: 10px; bottom:0; color:#265b5d;"
                      >{{ chat.timestamp }}</span
                    >
                  </div>
                  <div class=" pt-1">
                    <div
                      class="btn-circle avatar ml-2 "
                      style="width:25px; height:25px; line-height:25px; font-size:12px; float:right;"
                    >
                      {{ chat.user.name.split("-")[1][0].toUpperCase() }}
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <hr />
            <div class="input-group m-2 mobile-input">
              <!-- <button
                class="btn mr-1"
                style="padding: 0; background: transparent;"
                type="button"
              >
                <i class="far fa-lg fa-smile"></i>
              </button> -->
              <form @submit.prevent="sendMsg">
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="msg"
                  @input="msg = $event.target.value"
                  placeholder="Write your message"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  style="border: 0;width: 85vw;"
                />
              </form>
              <div class="input-group-append ml-1">
                <!-- <ModalUpload  :session="session" :publisherData="publisherData" v-if="chatMobile"/> -->
                <!-- <button class="btn " style="padding: 0; background: transparent;" type="button">
                  <i class="fal fa-lg  fa-plus-circle"></i>
                </button> -->
                <button
                  class="btn send-icon"
                  type="button"
                  @click.prevent="sendMsg"
                  :disabled="isEmpty"
                >
                  <!-- <i
                    class="far fa-lg fa-chevron-circle-right"
                    @click.prevent="sendMsg"
                  ></i> -->
                  <send-icon />
                </button>
              </div>
            </div>
          </div>

          <div class="container call-button">
            <div class="row justify-content-center">
              <div
                class="col-md-5 justify-content-center control-box col-10"
                style="backgroundColor: #393839;"
              >
                <div class="row">
                  <div
                    id="timer"
                    class="col-3 py-2"
                    style="color: white;border-right: inset;border-right-color: #322f33;"
                  >
                    <span class="values">
                      00:00:00
                    </span>
                  </div>
                  <!-- <div class="col-1"></div> -->
                  <div
                    id="devices-list2"
                    :class="classFade"
                    v-show="devicesList"
                  >
                    <div id="devices-box">
                      <!-- style="overflow-y: scroll; scroll-behavior: smooth;" -->

                      <button
                        v-for="device in camDevices"
                        :key="device.deviceId"
                        class="device-name"
                        @click="selectCam(device.deviceId)"
                      >
                        <span class="device-icon">
                          <i class="fas fa-video"></i>
                        </span>
                        <p style="margin-bottom: 0">
                          {{ device.label }}
                        </p>
                      </button>
                    </div>
                  </div>
                  <div id="list-cam" class="col py-2" @click="openList">
                    <span>
                      <i class="fas fa-list" style="color: #FFFEFF;"></i>
                    </span>
                  </div>
                  <div id="toggle-video" class="col py-2" @click="toggleVideo">
                    <span>
                      <i
                        class="fas fa-video"
                        v-if="mainCam"
                        style="color: #FFFEFF;"
                      ></i>
                      <i
                        v-else
                        class="fas fa-video-slash"
                        style="color: #77737A;"
                      ></i>
                    </span>
                  </div>
                  <div id="toggle-audio" class="col py-2" @click="toggleAudio">
                    <span>
                      <i
                        class="fas fa-microphone buttonGreen"
                        v-if="audio"
                        style="color: #FFFEFF;"
                      ></i>
                      <i
                        v-else
                        class="fas fa-microphone-slash"
                        style="color: #77737A;"
                      ></i>
                    </span>
                  </div>
                  <div id="goSs" class="col py-2" @click="confirmScreenShare">
                    <span>
                      <i class="far fa-desktop buttonGreen"></i>
                    </span>
                  </div>
                  <div id="end-session" class="col pt-1" @click="endCall()">
                    <span>
                      <!-- <i class="fas fa-phone-slash" style="color: white;"></i> -->
                      <img
                        src="../assets/phone-call.svg"
                        alt="tutup"
                        style="width: 30px"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 sideRight">
          <div
            id="participant"
            class="pt-2"
            style="height: 30%; margin-bottom: 2rem;"
          >
            <h6 style="color: #3A77B7 ">
              <strong>Participant ({{ streams.length }})</strong>
            </h6>
            <modalShareLink :infoId="'web'" />
            <hr />
            <div
              class="participant-box pr-2"
              style="overflow-x: hidden; height: 60%;"
            >
              <div
                v-for="(user, index) in streams.slice().reverse()"
                :key="index"
                style="height: 40px; margin-left: .5rem ;margin-right: 1rem;"
              >
                <div class="row d-flex justify-content-between">
                  <div
                    style="padding-right: 0;"
                    :class="'col-8 row text-truncate pt-1 flex-nowrap'"
                  >
                    <div class="col-2 align-self-center">
                      <div
                        class="btn-circle avatar mr-2"
                        style="width:25px; height:25px; line-height:25px; font-size:12px; "
                      >
                        {{ user.name.split("-")[1][0].toUpperCase() }}
                      </div>
                    </div>
                    <div class="col-9">
                      <p
                        style="margin:0; padding-top:; font-size: 16px; text-align: left;	
      align-self: left;"
                        v-if="user.name.split('-')[1] !== userName"
                      >
                        <!-- {{ nameCleaner(user.name.split("-")[1]) }} -->
                        {{
                          checkScreenName(user.name)
                            ? nameCleaner(user.name.split("-")[1]) + "'s Screen"
                            : nameCleaner(user.name.split("-")[1])
                        }}
                      </p>
                      <p
                        style="margin:0; padding-top:; font-size: 16px; text-align: left;	
      align-self: left;"
                        v-else
                      >
                        {{ user.name.split("-")[1] }} (Me)
                      </p>
                    </div>
                  </div>
                  <div
                    :class="'col-4 pt-1 row pr-2 justify-content-end'"
                    style="padding: 0;"
                  >
                    <i
                      v-if="checkScreenName(user.name)"
                      class="fas fa-desktop fa-lg buttonGreen"
                      style="width: 35px ;font-size: 14px; margin-top:4px"
                    ></i>
                    <i
                      class="fas fa-video buttonGreen mr-2"
                      v-if="user.hasVideo"
                    ></i>
                    <i
                      v-else
                      class="fas fa-video-slash mr-2"
                      style="color: #e22b2b"
                    ></i>
                    <i
                      class="fas fa-microphone buttonGreen mr-2"
                      v-if="user.hasAudio"
                    ></i>
                    <i
                      v-else
                      class="fas fa-microphone-slash"
                      style="color: #e22b2b"
                    ></i>
                    <i
                      v-if="
                        roleDokter &&
                          user.id !== publisherData.id &&
                          !checkScreenName(user.name)
                      "
                      class="fas fa-bars mr-2"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                    ></i>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="reqOpen(user, 'cam')"
                        >Ask Camera</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="reqOpen(user, 'mic')"
                        >Ask Microphone</a
                      >
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <hr />
          <div id="chat" style="height: 60%;">
            <h6 style="color: #3A77B7 "><strong>Chat</strong></h6>
            <hr />
            <div
              class="container"
              id="chat-box"
              style="overflow-y: scroll; height: 65%; scroll-behavior: smooth;"
            >
              <div v-for="(chat, index) in chats" :key="index">
                <div class="row" v-if="chat.user.id !== publisherData.id">
                  <div class="col-2 pt-1">
                    <div
                      class="btn-circle avatar mr-2"
                      style="width:25px; height:25px; line-height:25px; font-size:12px; float:right;"
                    >
                      {{ chat.user.name.split("-")[1][0].toUpperCase() }}
                    </div>
                  </div>
                  <div class="col-10" style="padding-left: 0;">
                    <img
                      v-if="chat.props"
                      :src="chat.content"
                      alt=""
                      style="width: 100%; height: 100%;"
                      @click="mencoba(chat.content)"
                    />
                    <p
                      v-else
                      class="text-left text-wrap"
                      style="margin: 0; word-wrap: break-word; "
                    >
                      {{ chat.content }}
                    </p>
                    <span
                      class="time-right ml-1"
                      style="float:left; font-size: 10px;"
                      >{{ chat.timestamp }}</span
                    >
                  </div>
                </div>

                <div class="row" v-else style="padding: 0px;">
                  <div class="col-10" style="padding-right: 0;">
                    <img
                      v-if="chat.props"
                      :src="chat.content"
                      alt=""
                      style="width: 100%; height: 100%;"
                      @click="mencoba(chat.content)"
                    />
                    <p
                      v-else
                      class="text-right text-wrap"
                      style="margin: 0; word-wrap: break-word; "
                    >
                      {{ chat.content }}
                    </p>
                    <span
                      class="time-right mr-1"
                      style="float:right; font-size: 10px; bottom:0;"
                      >{{ chat.timestamp }}</span
                    >
                  </div>
                  <div class="col-2 pt-1">
                    <div
                      class="btn-circle ml-2 "
                      style="width:25px; height:25px; line-height:25px; font-size:12px; float:right;"
                    >
                      {{ chat.user.name.split("-")[1][0].toUpperCase() }}
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <hr />
            <div class="input-group mb-3 row">
              <div class="col-md-2 p-1">
                <emoji-picker @emoji="insert" :search="search">
                  <div
                    class="emoji-invoker"
                    slot="emoji-invoker"
                    slot-scope="{ events: { click: clickEvent } }"
                    @click.stop="clickEvent"
                  >
                    <button
                      class="btn"
                      style="padding: 0; backgroundColor: transparent;"
                      type="button"
                    >
                      <i class="far fa-lg fa-smile"></i>
                    </button>
                  </div>
                  <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                    <div class="emoji-picker">
                      <div>
                        <input type="text" v-model="search" />
                      </div>
                      <div>
                        <div
                          v-for="(emojiGroup, category) in emojis"
                          :key="category"
                        >
                          <h5>{{ category }}</h5>
                          <div class="emojis">
                            <span
                              v-for="(emoji, emojiName) in emojiGroup"
                              :key="emojiName"
                              @click="insert(emoji)"
                              :title="emojiName"
                              >{{ emoji }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </emoji-picker>
              </div>
              <div
                class="col-md-9 p-0"
                style="border: solid 1px #ccc; border-radius: .25rem;"
              >
                <form @submit.prevent="sendMsg">
                  <input
                    type="text"
                    class="form-control"
                    v-model.lazy="msg"
                    @input="msg = $event.target.value"
                    placeholder="Write your message"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style="border: 0; backgroundColor: transparent;"
                  />
                </form>
              </div>
              <div class="col-md-1 p-1">
                <div class="input-group-append">
                  <!-- <ModalUpload  :session="session" :publisherData="publisherData"/> -->
                  <!-- <label class="custom-file-upload">
                  <input type="file" @change="uploadImage($event)" id="file-input"/>
                  <i class="fa fa-cloud-upload"></i>
              </label> -->
                  <!-- <button
                    class="btn"
                    style="padding: 0; backgroundColor: transparent;"
                    type="button"
                    
                  >
                    <i class="fal fa-lg  fa-plus-circle"></i>
                    <input type="file" @change="uploadImage($event)" id="file-input">
                  </button> -->
                  <button
                    class="btn ml-2 send-icon"
                    style="padding: 0; backgroundColor: transparent;"
                    type="button"
                    :disabled="isEmpty"
                    @click="sendMsg"
                  >
                    <send-icon />
                    <!-- <i
                      class="fas fa-lg fa-paper-plane"
                      style="color:green"
                      @click.prevent="sendMsg"
                    ></i> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="imagemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="maxWidth: 80vw; maxHeight: 80vh;"
      >
        <div class="modal-content">
          <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">image preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
          <div class="modal-body ">
            <button
              type="button"
              class="close "
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <a href="" id="downloadPrevImg" download>
              <button type="submit" class="close mr-3">
                <i class="far fa-cloud-download-alt"></i>
                <!-- <span aria-hidden="true">&times;</span> -->
              </button>
            </a>
            <img
              src=""
              class="mt-3"
              id="imagepreview"
              style="max-width: 100%;
  height: auto;"
            />
          </div>
          <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> -->
        </div>
      </div>
    </div>
    <!-- <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input"> -->
    <!-- before
    <img :src="uploadImg" class="before" style="max-heigt: 25px; width:100%;" />
      after
    <img :src="previewImage" class="after" style="max-heigt: 100%; width:100%;"/> -->
    <!-- <input type="file" accept="image/jpeg" @change=uploadImage> -->
    <button class="btn-float btn-primary-telekonsul border-radius-24" @click="sendWA">
      <i class="fab fa-whatsapp"></i>
      <span class="animate__animated animate__fadeIn"> Need Help?</span>
    </button>
  </div>
</template>

<script>
import OT, { checkScreenSharingCapability, log } from "@opentok/client";
import initLayoutContainer from "opentok-layout-js";
import EmojiPicker from "vue-emoji-picker";
import Axios from "axios";
import imageCompression from "browser-image-compression";
import ModalUpload from "../components/ModalUpload";
import ModalPassword from "../components/ModalPassword";
import modalShareLink from "../components/ModalShareLink.vue";
import { Timer } from "easytimer.js";
const timer = new Timer();
import Swal from "vue-sweetalert2";
import SendIcon from "../components/icons/SendIcon.vue";
import sendWhatsapp from '@/helpers/sendText'
import Repository from '@/helpers/repository'

export default {
  name: "Telekonsultasi",
  data() {
    return {
      url: "",
      userName: "",
      chats: [],
      previewImage: null,
      uploadImg: null,
      index: 0.9,
      imgSize: [],
      limitCompress: 5000,
      newNotif: 0,
      msg: "",
      roleDokter: this.$store.state.roleDokter,
      gtoken: "",
      stremOn: "",
      source: "",
      countUsers: 0,
      showWebCam: true,
      mainCam: true,
      audio: true,
      shareScreen: false,
      apiKey: process.env.VUE_APP_API_KEY,
      sessionId: this.$store.state.sessionId,
      token: this.$store.state.accessToken,
      guestToken: "",
      streams: [],
      session: null,
      publisher: null,
      jadwal: this.$store.state.telekonsulData,
      publisherData: {
        id: null,
        name: null,
        // poli: this.$store.state.telekonsulData.reservasi.jadwal.dokter.tujuanRujukan.nama
      },
      chatMobile: false,
      partiMobile: false,
      search: "",
      camDevices: [],
      devicesList: false,
      webDevicesList: false,
      classFade: "animate__animated animate__fadeIn",
      currentCam: null,
      lastScreenShare: null,
      isShareScreen: false,
    };
  },
  components: {
    EmojiPicker,
    ModalUpload,
    ModalPassword,
    SendIcon,
    modalShareLink,
  },
  computed: {
    publisherStyle() {
      return {
        borderRadius: "10px",
      };
    },
    isEmpty() {
      return this.msg.trim() === "";
    },
    reservasi () {
      return this.$store.state.dataTelekonsul.reservasi
    },
    hari () {
      let dataHari = this.jadwal.hari ? this.jadwal.hari : 'MONDAY'
      return Repository.changeDay(dataHari)
    },
    tanggalKunjungan () {
      let dataTanggal = this.reservasi ? this.reservasi.tanggalKunjungan : ''
      return Repository.formatDate(dataTanggal)
    }
  },
  
  methods: {
    async sendWA () {
      // console.log(data)
      const getConfig = await Repository.getConfigMessage(this.$store.state.dataTelekonsul.reservasi.tenantId)
      let message = ''
      if(this.$store.state.roleDokter) {
         message = getConfig.message.dokter
      } else {
         message = getConfig.message.pasien
      }
      const urlWA = sendWhatsapp.sendWhatsapp(this.$store.state.dataTelekonsul, message)
      setTimeout(() => {
          window.open(`${urlWA}`, '_blank');
      }, 100)
    },
    openList() {
      if (this.devicesList) {
        this.classFade = "animate__animated animate__fadeOut";
        setTimeout(() => {
          this.devicesList = false;
        }, 1000);
      } else {
        this.devicesList = true;
        this.classFade = "animate__animated animate__fadeIn";
      }
    },
    updateMsg(val) {
      this.msg = val;
    },
    checkScreenName(str) {
      if (str.match(/screenlayar/gi)) {
        this.isShareScreen = true;
        return true;
      }
      return false;
    },
    nameCleaner(str) {
      return str.replace("screenlayar", "");
    },
    closeTab() {
      window.top.close();
    },
    reqOpen(userId, req) {
      let vx = this;
      vx.session.signal(
        {
          type: "msg",
          data: JSON.stringify({
            userId: userId ? userId : "",
            req: req,
          }),
        },
        function signalCallback(error) {
          if (error) {
            console.error("Error sending signal: ", error.name, error.name);
          }
        }
      );
    },
    prompReq(req) {
      let val = "on";
      if (req == "cam") {
        val = this.mainCam ? "off" : "on";
      } else {
        val = this.audio ? "off" : "on";
      }
      this.$swal({
        title: `Do you want to turn ${val} ${req}?`,
        showCancelButton: true,
        confirmButtonText: `yes`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (req == "cam") {
            this.toggleVideo();
          } else {
            this.toggleAudio();
          }
          Swal.fire("Saved!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    confirmScreenShare() {
      this.$swal({
        title: `Do you want to share your screen?`,
        showCancelButton: true,
        confirmButtonText: `yes`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.goShareScreen();
          Swal.fire("Saved!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    mencoba(src) {
      // $('#imagepreview').attr('src', $('#imageresource').attr('src'));
      document.getElementById("imagepreview").src = src;
      document.getElementById("downloadPrevImg").href = src;
      $("#imagemodal").modal("show");
    },
    async uploadImage(e) {
      let vx = this;
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", event.target.files[0]);
      let config = {
        headers: {
          // 'Content-Type' : 'application/json;charset=UTF-8',
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJBZG1pbmlzdHJhdG9yIiwibGFzdE5hbWUiOiJBZG1pbmlzdHJhdG9yIiwidXNlcl9uYW1lIjoiYWRtaW4iLCJzY29wZSI6WyJvcGVuaWQiXSwiZXhwIjoxNjA1OTY4MDk3LCJ1c2VySWQiOjMsImlhdCI6MTYwNTY2NTY5NywiYXV0aG9yaXRpZXMiOlsiUk9MRV9GSU5BTkNFIiwiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJlbWFpbCI6ImFkbWluQGxvY2FsaG9zdC5jb20iLCJqdGkiOiIwNTYzODA2NC00YWZmLTQ0ODktYWRjMC1iYTVkNjRhOTYxNGQiLCJjbGllbnRfaWQiOiJ3ZWJfYXBwIn0.G6MWnLfWyeV2VF6K6LwpKCZpQE4nUo5MfBsADdV86ZZ95wj7nNn6RT0_YenynVf5fpsR9Rj13KdGflyO8k1hqGG-dMV_HmCmChNze_EAIuVIXEGXaYnD8B_cSqWSY_JR-VU61B0MXyAe1CMo1AJ9QIV7dTOuZVPxdZnoNBl_T0-4-PcJZ0R_SluO_IW8Mt2Kezc_Efxi9oaHgfSYNVJ4PvqS-2Voql0TiE7ULUMw2EC8Hyb258Zg9H99sFPRrPGxNUUMxbw7v8oBy8A1fU68op4_xOMndvw8hKvots2eczIie637cHYYTDudIzGzlklAfkAYY8MOTwtj9nSRboECPQ",
        },
      };

      const imageFile = e.target.files[0];
      this.uploadImg = window.URL.createObjectURL(e.target.files[0]);
      // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      // console.log(`originalFile size ${Math.round(imageFile.size / 1024 / 1024)} MB`);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        // let c = await uploadToServer(compressedFile); // write your own logic
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          const words = this.previewImage.split(",");
        };
        // console.log(compressedFile);
      } catch (error) {
        console.log(error);
      }

      let res = 5000;
      let ind = 1;
      // this.imgSize = 8000
      // let baru
      let n = 8000;
      let m;

      // while (n > res) {
      //   n-=1000
      //   ind = (ind * 10 - 0.1 * 10) / 10
      //   let baru = await this.ImageCompressor(image, ind)
      //   setTimeout(() => {
      //     console.log(baru);
      //   }, 500);
      // }

      // compressor 1 test
      // let baru = await this.ImageCompressor(image, 0.5)
      // const reader = new FileReader();
      // reader.readAsDataURL(baru.file);
      // reader.onload = e =>{
      //     this.previewImage = e.target.result;
      // const words = this.previewImage.split(',');
      // };

      // const reader = new FileReader();
      // reader.readAsDataURL(image);
      // reader.onload = e =>{
      //     this.uploadImage = e.target.result;
      //     console.log(this.uploadImage);
      // const words = this.previewImage.split(',');
      // let body = {
      //   image: words[1],
      //   height : 800,
      //   width : 620,
      //   imageContentType : "image/png",
      //   title : "ganda"
      // }
      // Axios.post(
      //     'https://dgex.mandayamedical.group/gallery/api/photos',
      //     body,
      //     config
      //   ).then(
      //     ({data}) => {
      //       console.log('image upload response > ', data)
      //       this.msg = `https://dgex.mandayamedical.group/gallery/api/image/alt/${data.alias}`
      //       this.sendMsg()
      //       this.previewImage = null
      //     })
      //   .catch(
      //     err =>{
      //       console.log(err);
      //     })
      // };
    },
    insert(emoji) {
      this.msg += emoji;
    },
    togglePartiMobile(command) {
      if (command == "open") {
        this.partiMobile = true;
        const element = document.querySelector("#partiMobile");
        element.classList.remove("animate__animated", "animate__fadeOutDown");
        element.classList.add("animate__animated", "animate__fadeInUp");
      } else {
        const element = document.querySelector("#partiMobile");
        element.classList.add("animate__animated", "animate__fadeOutDown");
      }
    },
    closeChatMobile(command) {
      $("#exampleModalCenter").modal("hide");
      if (command == "open") {
        this.chatMobile = true;
        const element = document.querySelector("#chatMobile");
        element.classList.remove("animate__animated", "animate__fadeOutDown");
        element.classList.add("animate__animated", "animate__fadeInUp");
        this.newNotif = 0;
      } else {
        const element = document.querySelector("#chatMobile");
        element.classList.add("animate__animated", "animate__fadeOutDown");
      }
      // this.chatMobile = false
    },
    goShareScreen() {
      let vx = this;
      var layoutContainer = document.getElementById("layoutContainer");

      const options = {
        ignoreClass: "OT_ignore",
        bigClass: "OT_big",
        alignItems: "center",
      };
      var layout = initLayoutContainer(layoutContainer, options).layout;

      OT.checkScreenSharingCapability(function(response) {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing.
        } else if (response.extensionInstalled === false) {
          // Prompt to install the extension.
        } else {
          // console.log(response);
          let screenPublisher = OT.initPublisher(
            "layoutContainer",
            {
              insertMode: "append",
              videoSource: "screen",
              publishAudio: true,
              name: vx.publisherData.name + " screenlayar",
              fitMode: "contain",
              buttonDisplayMode: false,
              // maxResolution: { width: 1280, height: 720 }, // max resolution to encode screen in
              // width: 1280, // width of preview
              // height: 720, // height of preview
            },

            function(error) {
              if (error) {
                // Look at error.message to see what went wrong.
              } else {
                // console.log("Publisher created.");
                // console.log(screenPublisher, "screenPublisher");
                // console.log(vx.lastScreenShare, "vx.lastScreenShare");

                vx.lastScreenShare = screenPublisher;
                // setTimeout(() => {
                screenPublisher.element.setAttribute("class", "OT_big");
                vx.session.publish(screenPublisher, {}, (err) => {
                  if (err) {
                    console.log(err);
                  }
                });
                vx.publisher.element.classList.remove("OT_ignore", "publisher");
                vx.publisher.element.classList.add("OT_subscriber");
                layout();
                // }, 5000);
              }
            }
          );

          // screenPublisher.on("streamCreated", (event) => {
          //   vx.streams.push(event.stream);
          // });
          if (vx.isShareScreen) {
            vx.isShareScreen = false;
            vx.reqOpen(null, "screen");
          } else if (vx.lastScreenShare) {
            vx.stopShare("screen");
          }
          screenPublisher.on("streamDestroyed", function(event) {
            // console.log(event, "event");
            event.preventDefault();
            const list = vx.session.getPublisherForStream(event.stream);
            list.element.remove();
            if (event.reason == "mediaStopped") {
              vx.publisher.element.removeAttribute("style");
              vx.publisher.element.classList.remove("OT_subscriber");
              vx.publisher.element.classList.add("OT_ignore", "publisher");
            }
            layout();
          });
        }
      });
    },
    stopShare(req) {
      if (req == "screen") {
        if (this.lastScreenShare) {
          this.session.unpublish(this.lastScreenShare);
          this.lastScreenShare.destroy();
        }
      }
    },
    switchCam() {
      this.publisher.cycleVideo();
    },
    async selectCam(id) {
      const videoSource = this.publisher.getVideoSource();
      // console.log(videoSource.deviceId);

      try {
        this.publisher.setVideoSource(id);
      } catch (error) {
        console.log(error, "<<<<<<<<<<<<");
      }
      this.devicesList = !this.devicesList;
      // this.publisher
      //   .setVideoSource(id)
      //   .then((res) => console.log(res))
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    toggleVideo() {
      let device = this.publisher.getVideoSource();
      this.currentCam = device.deviceId;
      this.mainCam = !this.mainCam;
      this.publisher.publishVideo(this.mainCam);
      if (x.classList.contains("b-mobile") && !x.classList.contains("iphone")) {
        this.publisher.setVideoSource(this.currentCam);
        // this.switchCam();
      }
    },
    toggleAudio() {
      this.audio = !this.audio;
      this.publisher.publishAudio(this.audio);
    },
    sendMsg() {
      let vx = this;
      if (vx.msg.trim() !== "") {
        let payloadMsg = {
          user: {
            name: vx.publisherData.name, // bind sesuai data user/publisher (dokter / pasien)
            id: vx.publisherData.id,
          },
          content: vx.msg,
          timestamp: new Date().toLocaleString("ID"),
        };

        vx.session.signal(
          {
            type: "msg",
            data: JSON.stringify(payloadMsg),
          },
          function signalCallback(error) {
            if (error) {
              console.error("Error sending signal: ", error.name, error.name);
            } else {
              vx.msg = "";
            }
          }
        );
      }
    },
    addTime() {
      timer.start();
      timer.addEventListener("secondsUpdated", function(e) {
        $("#timer .values").html(timer.getTimeValues().toString());
      });

      timer.addEventListener("started", function(e) {
        $("#timer .values").html(timer.getTimeValues().toString());
      });

      timer.addEventListener("reset", function(e) {
        $("#timer .values").html(timer.getTimeValues().toString());
      });
    },
    endCall() {
      let vx = this;
      vx.publisher.publishVideo(false);
      vx.publisher.publishAudio(false);
      vx.session.disconnect(vx.session.connection.id);
      this.$router.push("endCall");
    },
    encodeUriParams(obj) {
      var str = [];
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }
      return str.join("&");
    },
    copyShareLink() {
      const el = document.createElement("textarea");
      const url = process.env.VUE_APP_BASE_URL;
      const inviteLink = localStorage.getItem("linkParams");

      el.value = `${url}${inviteLink}`;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$vToastify.success("Link berhasil disalin");
    },
  },
  mounted() {
    if (this.$store.state.accessToken == null) {
      // console.log('masuk di if');
      // this.$router.push(`/${samepar}`)
      // this.session.disconnect();
    } else {
      localStorage.setItem(
        "telmedinfo",
        JSON.stringify(this.$store.state.telekonsulData)
      );

      // this.jadwal = this.$store.state.telekonsulData.reservasi.jadwal
    }

    var ua = navigator.userAgent;
    // console.log(ua);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var x = document.getElementsByTagName("BODY")[0];
    if (isSafari) {
      x.classList.add("b-mobile");
      if (/iPhone|iPod/i.test(ua)) {
        x.classList.add("iphone");
      }
    } else if (
      /Android|webOS|Tablet|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
        ua
      )
    ) {
      x.classList.add("b-mobile");
    } else /Chrome/i.test(ua);
    // $('a.chrome').show();
    // else
    // $('a.desktop-other').show();

    let vx = this;
    var layoutContainer = document.getElementById("layoutContainer");

    const options = {
      ignoreClass: "OT_ignore",
      bigClass: "OT_big",
      alignItems: "center",
    };

    // Initialize the layout container and get a reference to the layout method
    var layout = initLayoutContainer(layoutContainer, options).layout;

    // init publisher
    vx.publisher = OT.initPublisher("publisherContainer", {
      showControls: false,
      name: vx.$store.state.userData.name,
      publishAudio: true,
      mirror: false,
      height: "100%",
      width: "100%",
      facingMode: "user",
    });

    //init session
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.connect(this.token, (err) => {
      if (err) {
        if (err.name === "OT_NOT_CONNECTED") {
          console.log(
            "Failed to connect. Please check your connection and try connecting again."
          );
        } else {
          console.log(
            "An unknown error occurred connecting. Please try again later."
          );
        }
      } else {
        vx.session.publish(vx.publisher);
        vx.publisher.element.classList.add("OT_ignore");
        vx.addTime();
        layout();
      }
    });

    vx.session.on("streamCreated", function(event) {
      // di sini ?
      console.log("session stream created");
      vx.streams.push(event.stream);
      var subOptions = { insertMode: "append" };
      if (event.stream.videoType === "screen") {
        let screenSubscribe = vx.session.subscribe(
          event.stream,
          "layoutContainer",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
          }
        );
        console.log("stream layout container");
        screenSubscribe.element.setAttribute("class", "OT_big");
        vx.publisher.element.classList.remove("OT_ignore", "publisher");
        vx.publisher.element.classList.add("OT_subscriber");
      } else {
        vx.session.subscribe(event.stream, "layoutContainer", subOptions);
      }
      layout();
    });

    vx.publisher.on("streamCreated", function(event) {
      vx.streams.push(event.stream);
      vx.publisherData.name = event.stream.name;
      vx.userName = event.stream.name.split("-")[1];
      vx.publisherData.id = event.stream.id;
      vx.audio = event.stream.hasAudio;
      vx.mainCam = event.stream.hasVideo;
    });

    // ini yg listen di client / subscriber
    vx.session.on("streamDestroyed", (event) => {
      event.preventDefault();
      vx.session.getSubscribersForStream(event.stream).forEach((subscriber) => {
        subscriber.element.remove();
        setTimeout(() => {
          const idx = vx.streams.indexOf(event.stream);
          if (idx > -1) {
            vx.streams.splice(idx, 1);
          }
          // layout();
        }, 200);
      });
      vx.publisher.element.removeAttribute("style");
      vx.publisher.element.classList.remove("OT_subscriber");
      vx.publisher.element.classList.add("OT_ignore", "publisher");
      layout();
    });

    // vx.publisher.on("streamDestroyed", (event) => {
    //   event.preventDefault();
    //   // hapus dari stream ?
    //   console.log("The publisher stopped streaming. Reason: " + event.reason);
    // });

    vx.session.on("signal:msg", function signalCallback(event) {
      let pesan = JSON.parse(event.data);
      if (!pesan.req) vx.chats.push(pesan);
      if (pesan.req) {
        if (pesan.userId.id == vx.publisherData.id) {
          vx.prompReq(pesan.req);
        } else if (pesan.req == "screen") {
          vx.stopShare(pesan.req);
        }
      } else if (pesan.user.id !== vx.publisherData.id) {
        vx.newNotif += 1;
      }
      var chatBox = document.getElementById("chat-box");
      var chatBoxMobile = document.getElementById("chat-box-mobile");
      setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight;
        chatBoxMobile.scrollTop = chatBoxMobile.scrollHeight;
      }, 10);
    });

    vx.session.on("signal:endCall", function signalCallback(event) {
      vx.endCall();
    });

    // vx.session.on("connectionDestroyed", function(event) {
    //   console.log(event, 'connection destroyed');
    // });

    var resizeTimeout;
    window.onresize = function() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(function() {
        layout();
      }, 20);
    };
    OT.getDevices((error, devices) => {
      devices.filter((device) => {
        if (device.kind === "videoInput") {
          this.camDevices.push(device);
        }
      });
    });
    // console.log(document.getElementsByClassName('nav2')[0].clientHeight)
    const heigtData = document.getElementsByClassName('nav2')[0].clientHeight
    if(heigtData > 0) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.$refs['camera-data'].style.height = `calc((var(--vh, 1vh) * 100) - ${heigtData}px)`
    }
    // console.log(this.camDevices, "cam devices");
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "endCall") {
      next();
    }
  },
};
</script>

<style></style>
