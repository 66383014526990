<template>
  <div>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width: 25px;" xml:space="preserve">
    <path id="icon-send1" d="M512,256.001c0,20.361-11.68,37.862-30.491,45.662L68.412,473.08
      c-17.036,7.068-36.766,4.295-51.312-8.29C2.859,452.46-3.101,433.449,1.559,415.198L38.33,271.002l3.82-15.001L38.33,241
      L1.559,96.805c-4.66-18.251,1.3-37.262,15.541-49.592c14.084-12.185,33.727-15.586,51.312-8.29L481.509,210.34
      C500.32,218.14,512,235.641,512,256.001z"/>
    <path id="icon-send2" d="M38.331,271.002L1.559,415.198c-4.66,18.251,1.3,37.261,15.541,49.592
      c14.546,12.586,34.276,15.359,51.312,8.29l413.097-171.417c18.811-7.8,30.491-25.301,30.491-45.662H42.151L38.331,271.002z"/>
    <path style="fill:#EFEFEF;" d="M233.349,256.001c0,8.28-6.71,15.001-15.001,15.001H38.331l3.82-15.001L38.331,241h180.017
      C226.638,241.001,233.349,247.721,233.349,256.001z"/>
    <path id="icon-send3" d="M38.331,271.002h180.017c8.29,0,15.001-6.72,15.001-15.001H42.151L38.331,271.002z"/>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>

</style>