<template>
  <div class="container-dekstop text-center">
    <label class="fs-32 fw-600 c-gray-A">{{textLang['ups']}}</label>
    <p class="fs-20 fw-700 c-primary">{{textLang['titleBlock2']}}</p>
    <div class="mt-3 checklist">
        <div class="mx-auto">
            <label class="fs-16 pb-16 m-0 cursor px-3 position-relative" :class="lang=='en' ? 'active fw-700': ' deactive fw-500'" @click="lang='en'">
                EN
            <label class="m-0"></label>
            </label>
            <label class="fs-16 pb-16 m-0 cursor px-3 position-relative" :class="lang=='id' ? 'active fw-700': 'deactive fw-500'" @click="lang='id'">
                ID
            <label class="m-0"></label>
            </label>
        </div>
    </div>
    <div class="bg-box-detail">
        <p class="fs-16 fw-700 c-gray-A margin-bottom-24">{{textLang['titleBox2']}}</p>
        <ul class="text-left list-decimal ">
            <li class="fs-16 fw-500 c-primary" v-html="textLang['list1_error']"></li>
            <li class="fs-16 fw-500 c-primary" v-html="textLang['list2_error']"></li>
            <li class="fs-16 fw-500 c-primary" v-html="textLang['list3_error']"></li>
        </ul>
        <ul class="text-left ml-3">
            <li class="fs-16 fw-500 c-primary" v-html="textLang['subList1_error']"></li>
             <li class="fs-16 fw-500 c-primary" v-html="textLang['subList2_error']"></li>
              <li class="fs-16 fw-500 c-primary" v-html="textLang['subList3_error']"></li>
               <li class="fs-16 fw-500 c-primary" v-html="textLang['subList4_error']"></li>
                <li class="fs-16 fw-500 c-primary" v-html="textLang['subList5_error']"></li>
        </ul>
      
    </div>
      <!-- <button class="btn-primary-telekonsul btn-block fs-20 fw-700 border-radius-24 margin-bottom-40" @click="reload">{{textLang['muatUlang']}}</button> -->
    <p class="fs-16 fw-700 c-primary ">Butuh Bantuan?</p>
    <div class="mx-auto d-grid-mobile">
        <button class="btn-primary-telekonsul fs-14 fw-700 mx-2 border-radius-8" @click="sendWA"><i class="fab fa-whatsapp"></i> WA Contact Center</button>
        <button class="btn-secondary-telekonsul fs-14 fw-700 mx-2 border-radius-8"  @click="goToTerm">Syarat dan Ketentuan</button>
    </div>
  </div>
</template>

<script>
import EN from '../../i18n/en.json'
import ID from '../../i18n/id.json'


export default {
    data() {
        return {
            lang: 'en',
            EN,
            ID
        }
    },
    computed: {
        textLang() {
            if(this.lang == 'en') {
                return EN
            } else {
                return ID
            }
        }
    },
    methods: {
        sendWA() {
           this.$emit('sendWhatsapp')
        },
        goToTerm () {
            this.$emit('goTerm')
        }
    }
}
</script>

<style>

</style>