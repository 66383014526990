<template>
  <div class="main-container">
    <img src="@/assets/image/caredokter.svg" class="img-care-dokter" />
    <div class="position-relative-full">
      <!-- <div class="flex-box-telemedicine"> -->
      <div class="position-absolute-center text-center">
        <p class="fs-16 fw-700 c-pink-care-doctor margin-bottom-24">
          Error 404
        </p>
        <label class="d-block fs-32 fw-600 c-gray-A margin-bottom-24"
          >Ups! Halaman tidak ditemukan</label
        >
        <label class="d-block fs-16 fw-500 c-primary"
          >Kemungkinan halaman telah dihapus atau Anda memasukkan URL yang tidak
          tepat</label
        >
        <label class="d-block fs-16 fw-500 c-primary"
          >Mohon cek kembali URL yang Anda masukkan</label
        >
      </div>
      <!-- </div> -->
      <!-- <img src="./../assets/notFound.png" alt="" class="w-50" />
    <h4>Halaman Tidak Ditemukan</h4> -->
      <img
        src="./../assets/image/404.png"
        alt=""
        class="position-image-auto-2"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style></style>
