<template>
 <div class="main-container">
  <img src="@/assets/image/caredokter.svg" class="img-care-dokter" />
  <div class="position-relative-full">
    <div class="position-absolute-center-2 text-center">
      <p class="fs-32 fw-600 c-gray-A margin-bottom-16">Terima Kasih</p>
      <p class="fs-20 fw-700 c-primary">Sesi Telekonsultasi telah berakhir</p>
      <div class="mt-5">
        <p class="c-gray-A fs-16 fw-700 mb-16">Butuh Bantuan?</p>

        <div class="mx-auto margin-bottom-32 d-grid-mobile">
          <button
            class="btn-primary-telekonsul fs-14 fw-700 mx-2 border-radius-8"
            @click="sendWA"
          >
            <i class="fab fa-whatsapp"></i> WA Contact Center
          </button>
          <button
            class="btn-secondary-telekonsul fs-14 fw-700 mx-2 border-radius-8"
            @click="goToTerm"
          >
            Syarat dan Ketentuan
          </button>
        </div>
      </div>
    </div>
    <!-- <h1>Terima Kasih</h1>
    <h2>sesi telekonsul telah berakhir</h2> -->
    <img src="./../assets/image/dokter.png" alt="" class="position-image-auto" />
  </div>
 </div>
</template>

<script>
import sendWhatsapp from '@/helpers/sendText'
import Repository from '@/helpers/repository'

export default {
  mounted() {
    // window.CHANNEL_NAME.postMessage("end call");
  },
  methods: {
    async sendWA () {
      const getConfig = await Repository.getConfigMessage(this.$store.state.dataTelekonsul.reservasi.tenantId)
      let message = ''
      if(this.$store.state.roleDokter) {
        message = getConfig.message.dokter
      } else {
        message = getConfig.message.pasien
      }
      const urlWA = sendWhatsapp.sendWhatsapp(this.$store.state.dataTelekonsul, message)
      setTimeout(() => {
          window.open(`${urlWA}`, '_blank');
      }, 100)
    },
    goToTerm () {
      this.$router.push({name: 'terms-and-conditions'})
    },
  }
};
</script>

<style></style>
