<template>
  <div>
  <!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  <i class="fa fa-cloud-upload"></i>
</button>

<!-- Modal -->

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Upload Image</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label fclass="custom-file-upload">
        <input type="file" @change="compressImage($event)" id="file-input"/>
          <i class="fa fa-cloud-upload"></i>
        </label><br>
        <img :src="previewImage" class="after" style="width: 100%; height: auto;"/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="sendImage()" data-dismiss="modal">Send</button>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import Axios from 'axios';
export default {
  name: 'ModalUpload',
  data(){
    return{
      previewImage: null,
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  }, 
  props: ['session', 'publisherData'],
  methods:{
    mencoba(){
      // ilangin backdrop bootstrap
//       $('.modal').on('hidden.bs.modal', function () {
//     $('.modal').remove();
// });
    },
    async compressImage(e){
      const imageFile = e.target.files[0];
      this.uploadImg = window.URL.createObjectURL(e.target.files[0])
      // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      // console.log(`originalFile size ${Math.round(imageFile.size / 1024 / 1024)} MB`);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
        // let c = await uploadToServer(compressedFile); // write your own logic
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onload = e =>{
            this.previewImage = e.target.result;
        const words = this.previewImage.split(',');
      };
        // console.log(compressedFile);
      } catch (error) {
        console.log(error);
      }
    },
    sendImage(){
      let config = {
      headers : {
        // 'Content-Type' : 'application/json;charset=UTF-8',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJhZG1pbiIsImxhc3ROYW1lIjoiZGV2IiwidXNlcl9uYW1lIjoiYWRtaW5fZGV2XzEiLCJzY29wZSI6WyJvcGVuaWQiXSwiZXhwIjoxNjA2Mzk5ODUxLCJ1c2VySWQiOjI0NDYzNywiaWF0IjoxNjA2MDk3NDUxLCJhdXRob3JpdGllcyI6WyJST0xFX0ZJTkFOQ0UiLCJST0xFX0FETUlOIiwiUk9MRV9VU0VSIl0sImVtYWlsIjoiYWRtaW5AZGV2LmNvbSIsImp0aSI6IjRlYjg0YzlhLTYyYWUtNDJkYi04Y2NmLTk5NGI3MGJlNjBiNiIsImNsaWVudF9pZCI6IndlYl9hcHAifQ.Dff7bPNymcuC1oJaGsNXOtpRUDocSK9B_ZUfXBikNWwirRDd8jOhNtBEnbhj_Kq0cRE5wg_fLX3CYJfjtQDR-t8jr8c1l8cvltRQtsDeLfkCqaTxznZAMzZC65hxiHkKGLFGftsU2kpzaGwFpj655YVtSZODX9hsgPdLDEiljCONPUhp_hgHfu-KxcJMLzF_aewTXvw3Y8niNnjHvWJwuTyJotQE_dnV26d8qhcIWknwhIWd4_v469ml5xIR26DyTHiZJR5DRwO7bhL4E62P21WvYfabEzoa1hcF_EMCpPmZGyiMr6Vdz9FFveR5RwroDdcssmv-N407q5G_1jqucg'
       },
      }
      const words = this.previewImage.split(',');
      let body = {
        image: words[1],
        height : 800,
        width : 620,
        imageContentType : "image/png",
        title : "ganda"
      }
      Axios.post(
          `${process.env.VUE_APP_BASE_URL}/gallery/api/photos`, 
          body,
          config
        ).then(
          ({data}) => {
            // console.log('image upload response > ', data)
            // this.sendMsg(words[1])
            let url = `${process.env.VUE_APP_BASE_URL}/gallery/api/image/alt/${data.alias}`
            this.sendMsg(url)
            this.previewImage = null
          })
        .catch(
          err =>{
            // console.log(err);
          })
    },
    sendMsg(payload){
      let vx = this;
      let payloadMsg = {
        user: {
          name: vx.publisherData.name, // bind sesuai data user/publisher (dokter / pasien)
          id: vx.publisherData.id,
        },
        props: 'image',
        content: payload,
        timestamp: new Date().toLocaleString("ID"),
      };

      vx.session.signal(
        {
          type: "msg",
          data: JSON.stringify(payloadMsg),
        },
        function signalCallback(error) {
          if (error) {
            console.error("Error sending signal: ", error.name, error.name);
          } else {
           
          }
        }
      );
    }
  }
}
</script>

<style>

</style>